import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card,
    CardGroup,
    Nav,
    NavItem,
    CardBody
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import Logo from "/public/site-assets/svg/logo-black.svg"
import ImpellamGroup from "/public/site-assets/svg/impellam-group.svg"
import React, {
    useRef,
    useEffect,
    useState
} from "react";
import { useRouter } from 'next/router';
import Sourceflow from '/public/sourceflow_logo.svg';
import SocialLinks from '/components/SocialLinks';
import FooterCopyrightNavigations from '../../.sourceflow/footer_copyright_navigations.json';
import FooterAboutLorienNavigations from '../../.sourceflow/footer_about_lorien_navigations.json';
import FooterJobSeekersNavigations from '../../.sourceflow/footer_job_seekers_navigations.json';
import FooterDocumentsNavigations from '../../.sourceflow/footer_documents_navigations.json';
import FooterSolutionsnNavigations from '../../.sourceflow/solutions.json';
import FooterSectorsNavigations from '../../.sourceflow/sectors.json';
import imageMetaData from "../../.sourceflow/image_metadata.json";

export default function MainFooter({
    global,
    pathPrefix,
    customClass = "",
}) {
    const router = useRouter();

    return (
        <section className={`${styles.root} ${customClass} position-relative`}>
            <Container>
                <hr></hr>
                <div className="pt-3">
                    <Link href="/">
                        <a className="logo d-block" aria-label="Carbon60 Logo" title="Carbon60">
                            {/* <Logo /> */}
                            <SourceFlowImage
                                src={`/site-assets/images/white-logo.png`}
                                size="200x"
                                alt={`Carbon60`}
                                className="card-img rounded-0"
                                imagesMetaData={imageMetaData}
                                path={pathPrefix ? pathPrefix : (`${pathPrefix}.image`) }
                            />
                        </a>
                    </Link>
                    <Row className="mt-4">
                        <Col md={12}>
                            <Row className="justify-content-between">
                                <Col md={6} lg={2}>
                                    <div className="mb-4 navigations">
                                        <p className="mb-1"><a href={`${router.pathname.startsWith("/us") ? "/us" : ""}/contact-us`}>Get in Touch</a></p>
                                        {/*<p className="mb-0">*/}
                                        {/*    <Link href="tel:02-076541000">*/}
                                        {/*        <a>*/}
                                        {/*            {router.pathname.startsWith("/us") ? "+1 954.433.4100  " : "02-076541000"}*/}
                                        {/*        </a>*/}
                                        {/*    </Link>*/}
                                        {/*</p>*/}
                                        <p>
                                            <Link href="mailto:enquiries@carbon60global.com">
                                                <a>
                                                    {router.pathname.startsWith("/us") ? "enquiries@carbon60global.com" : "enquiries@carbon60global.com"}
                                                </a>
                                            </Link>
                                        </p>
                                    </div>
                                    <SocialLinks
                                        customClass="social-links w-100 mb-5 d-flex align-items-end footer"
                                    />
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1"><a href={`${router.pathname.startsWith("/us") ? "/us" : ""}/job-seekers`}>Job Seekers</a></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterJobSeekersNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <p className="mt-5 mb-1"><a href={`${router.pathname.startsWith("/us") ? "/us" : ""}/contractors`}>Contractors</a></p>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1"><a href={`${router.pathname.startsWith("/us") ? "/us" : ""}/business-solutions`}>Business Solutions</a></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterSolutionsnNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/business-solutions/${post.url_slug}/`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <p className="mt-4 mb-1"><a href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors`}>Our Sectors</a></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterSectorsNavigations.category_values.filter((cat) => cat.solutions?.en === "Business Solutions").map((post, index) => (
                                            <>
                                            <li key={index} className="mt-2">
                                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/${post.url_slug}/`}>
                                                    <a className="text-decoration-none">
                                                        <strong>{post.title.en}</strong>
                                                    </a>
                                                </Link>
                                            </li>
                                            {FooterSectorsNavigations.category_values.filter((cat) => cat.parent?.id === post.id).map((child, index) => (
                                                <li key={index}>
                                                    <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/${child.url_slug}/`}>
                                                        <a className="text-decoration-none">
                                                            {child.title.en}
                                                        </a>
                                                    </Link>
                                                </li>
                                            ))}
                                            </>
                                        ))}
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1"><a href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/`}>News and Insights</a></p>
                                    <ul className="list-unstyled navigations" >
                                        {/* {FooterAboutLorienNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${post.link.en}`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))} */}
                                        <li >
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/tag/case-studies`}>
                                                <a className="text-decoration-none">
                                                    Case Studies
                                                </a>
                                            </Link>
                                        </li>
                                        <li >
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/tag/career-advice`}>
                                                <a className="text-decoration-none">
                                                    Career Advice
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1"><a href={`${router.pathname.startsWith("/us") ? "/us" : ""}/about-us`}>About Us</a></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterAboutLorienNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1">Documents</p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterDocumentsNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                {post?.new_tab ?
                                                    <Link href={`${post.link.en}`}>
                                                        <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                                            {post.title.en}
                                                        </a>
                                                    </Link> :
                                                    <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                                        <a className="text-decoration-none">
                                                            {post.title.en}
                                                        </a>
                                                    </Link>}
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-start mt-4">
                    <span className="impellem-group d-flex align-items-end me-4">
                        <small>Part Of</small>
                        <Link href="https://www.impellam.com/">
                            <a target="_blank" rel="noopener noreferrer" title="ImpellAm Group" className="ms-1 impellam-logo">
                                {/* <ImpellamGroup /> */}
                                <SourceFlowImage
                                    src={`/site-assets/images/impellam-white-logo.png`}
                                    size="200x"
                                    alt={`Impellam Group`}
                                    className="card-img rounded-0"
                                    imagesMetaData={imageMetaData}
                                    path={pathPrefix ? (`${pathPrefix}_2a.image`) : (`${pathPrefix}_2b.image`) }
                                />
                            </a>
                        </Link>
                    </span>
                    <div className="my-4 my-md-0">
                        <div className="my-4 my-md-0 text-left">
                            <small>
                                © Carbon60, All rights reserved 2019 | Registered Address: First Floor, Mulberry House, Parkland Square, 750 Capability Green, Luton, LU1 3LU | Company Number: 2209742
                                <br />
                                Some content on this site contains public sector information licensed under the Open Government Licence v3.0
                            </small>
                        </div>
                        <ul className="d-flex flex-wrap align-items-center justify-content-center list-unstyled footer-navigations-2 mx-auto my-3 pt-3">
                            {FooterCopyrightNavigations.category_values.map((post, index) => (
                                <li className="me-md-3 mb-1 pe-2 pe-md-0" key={index}>
                                    {post.new_tab ?
                                        <Link href={`${post.link.en}`}>
                                            <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                                <small>{post.title.en}</small>
                                            </a>
                                        </Link> :
                                        <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                            <a className="text-decoration-none">
                                                <small>{post.title.en}</small>
                                            </a>
                                        </Link>}

                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="text-nowrap d-flex align-items-end">
                        <p className="mb-0 me-3"><small>© {new Date().getFullYear()} Carbon60.</small></p>
                        <p className="mb-0 me-1"><small>Site by</small></p>
                        <Link href="https://www.sourceflow.co.uk/">
                            <a className="sourceflow" rel="noopener noreferrer" target="_blank" title="SourceFlow">
                                <Sourceflow />
                            </a>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}