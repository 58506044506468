import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import {
  Container,
  Nav,
  NavItem,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import styles from "./styles.module.scss";
import Link from "next/link";
import Logo from "/public/site-assets/svg/logo-white.svg";
import MainNavigation from "../../.sourceflow/menu_navigations.json";
import SolutionsNavigation from "../../.sourceflow/solutions.json";
import SectorsNavigation from "../../.sourceflow/sectors.json";
import SustainableNavigation from "../../.sourceflow/sustainable_recruitment.json";
import MobileNavigations from "../../.sourceflow/mobile_navigations.json";
import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "/public/site-assets/svg/black-magnifying-glass.svg";
import CloseMark from "/public/site-assets/svg/close-mark.svg";
import Telephone from "/public/site-assets/svg/telephone.svg";
import Arrow from "/public/site-assets/svg/black-arrow.svg"
import NewsTag from "../../.sourceflow/news_tag.json";
import News from "../../.sourceflow/news.json";
import Fuse from "fuse.js";
import { useRouter } from "next/router";
import imageMetaData from "../../.sourceflow/image_metadata.json";

import UKFlag from "/public/site-assets/svg/uk-flag.svg";
import USFlag from "/public/site-assets/svg/us-flag.svg";
import DEFlag from "/public/site-assets/svg/germany-flag.svg";

export default function MainHeader({ global, pathPrefix, content }) {
  const router = useRouter();

  const dropdownRefs = {};
  const [isScrolled, setIsScrolled] = useState(false);

  const [isAboutDropdownOpen, setAboutIsDropdownOpen] = useState(false);
  const [isInsightsDropdownOpen, setInsightsIsDropdownOpen] = useState(false);
  const [isSolutionssDropdownOpen, setSolutionsIsDropdownOpen] =
    useState(false);
  const [isContractorsDropdownOpen, setContractorsIsDropdownOpen] =
    useState(false);
  const [isJobSeekersDropdownOpen, setJobSeekerssIsDropdownOpen] =
    useState(false);
  const [isClientsDropdownOpen, setClientsIsDropdownOpen] = useState(false);
  const [isSectorsDropdownOpen, setSectorsIsDropdownOpen] = useState(false);
  const [isSustainableDropdownOpen, setSustainableIsDropdownOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  // Function to toggle a specific dropdown
  const toggleDropdown = (dropdownName) => {
    if (dropdownName === "About Us") {
      setAboutIsDropdownOpen(!isAboutDropdownOpen);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setSustainableIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Insights") {
      setInsightsIsDropdownOpen(!isInsightsDropdownOpen);
      setSolutionsIsDropdownOpen(false);
      setSustainableIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setAboutIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Solutions") {
      setSolutionsIsDropdownOpen(!isSolutionssDropdownOpen);
      setAboutIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setSectorsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Contractors") {
      setContractorsIsDropdownOpen(!isContractorsDropdownOpen);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setSustainableIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Job Seekers") {
      setJobSeekerssIsDropdownOpen(!isJobSeekersDropdownOpen);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setSustainableIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Business Solutions") {
      setClientsIsDropdownOpen(!isClientsDropdownOpen);
      setJobSeekerssIsDropdownOpen(false);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setSustainableIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Sectors") {
      setSectorsIsDropdownOpen(!isSectorsDropdownOpen);
      setSolutionsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setSustainableIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false); // Close the other dropdown
    } else if (dropdownName === "Sustainable") {
      setSustainableIsDropdownOpen(!isSustainableDropdownOpen);
      setSectorsIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setAboutIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);

      setInsightsIsDropdownOpen(false); // Close the other dropdown
    }
    // Add more conditions for other dropdowns if needed
  };

  // Function to handle clicks outside of all dropdowns
  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".dropdown-navigations") && // Check if clicked outside the dropdown-navigations
      !event.target.closest(".parent") // Check if clicked outside any dropdown button
    ) {
      setAboutIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
      setSectorsIsDropdownOpen(false);
      setSustainableIsDropdownOpen(false);
    }
  };

  // Function to close all dropdowns
  const closeAllDropdowns = () => {
    setAboutIsDropdownOpen(false);
    setInsightsIsDropdownOpen(false);
    setSolutionsIsDropdownOpen(false);
    setContractorsIsDropdownOpen(false);
    setJobSeekerssIsDropdownOpen(false);
    setClientsIsDropdownOpen(false);
    setSectorsIsDropdownOpen(false);
    setSustainableIsDropdownOpen(false);
    // Add more conditions for other dropdowns if needed
  };

  useEffect(() => {
    // Attach the event listener when any dropdown is open
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isOpen ? "hidden" : "auto";
    }

    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isOpen]);

  const AllInsights = News.category_values;

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Create an instance of Fuse with your data
  const fuseOptions = {
    includeScore: true,
    keys: ["title.en"],
  };

  const fuse = new Fuse(AllInsights, fuseOptions);

  // console.log(fuse);

  // Create a function to handle search
  const handleSearch = (query) => {
    const results = fuse.search(query);
    // setSearchResults(results.map((result) => result.title.en)); // Access 'title.en'
    setSearchResults(results);
  };

  // Add an event handler for the search input
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  // ReciteMe Script
  useEffect(() => {
    const enableReciteButton = document.getElementById("reciteEnable");

    if (enableReciteButton) {
      enableReciteButton.addEventListener("click", loadService);
    }

    return () => {
      if (enableReciteButton) {
        enableReciteButton.removeEventListener("click", loadService);
      }
    };
  }, []);

  const handleLinkClick = (urlSlug) => {
    // Reload the page when a link is clicked
    window.location.href = `/jobs#/${urlSlug}`;
    window.location.reload();
  };

  return (
    <>
      <section
        className={`${styles.root} main-navigation ${isOpen ? "active" : ""}`}
      >
        <div className="reciteWrapper py-2">
          <Container>
            <button
              className="recite-btn p-0"
              id="reciteEnable"
              aria-label="Accessibility and Languages"
            >
              Accessibility and Languages
            </button>
          </Container>
        </div>
        <Container className="position-relative mainWrapper">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Link
                href={`${router.pathname.startsWith("/us") ? "/us/" : "/"}`}
              >
                <a
                  className="logo me-md-5"
                  aria-label="Carbon60 Logo"
                  title="Carbon60"
                >
                  {/* <Logo /> */}
                  <SourceFlowImage
                    src={`/site-assets/images/white-logo.png`}
                    // src={`/site-assets/images/C60-pride-logo.png`}
                    size="400x"
                    alt={`Carbon60`}
                    className="card-img rounded-0"
                    imagesMetaData={imageMetaData}
                    path={pathPrefix ? pathPrefix : (`${pathPrefix}.image`)}
                  />
                </a>
              </Link>
              <Nav className="d-none d-lg-flex">
                {MainNavigation.category_values.map((post, index) => (
                  <NavItem key={index} className="parent mx-1">
                    <button
                      className={`border-0 bg-transparent dropdown ${post.has_children.en ? "has-children" : null
                        }`}
                      aria-label={`${post?.title.en}`}
                      onClick={() => toggleDropdown(post?.title.en)} // Pass the dropdown name
                      ref={(ref) => (dropdownRefs[post?.title.en] = ref)} // Use a separate ref for each dropdown
                    >
                      <strong>{post?.title.en}</strong>
                    </button>
                  </NavItem>
                ))}
              </Nav>
              {/* <UncontrolledDropdown className="lang_select d-none d-lg-flex">
                                <DropdownToggle aria-label="Language">
                                    <span
                                        className={
                                            router.pathname.startsWith("/") &&
                                                !router.pathname.startsWith("/us")
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <UKFlag />
                                    </span>
                                    <span
                                        className={router.pathname.startsWith("/us") ? "active" : ""}
                                    >
                                        <USFlag />
                                    </span>
                                    <span
                                        className={
                                            router.pathname.startsWith("/au") &&
                                                !router.pathname.startsWith("/us")
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <DEFlag />
                                    </span>
                                </DropdownToggle>

                                <DropdownMenu aria-labelledby="dropdownMenuButton">
                                    <DropdownItem
                                        href="/"
                                        title={`United Kingdom`}
                                        aria-label={`United Kingdom`}
                                        className={
                                            router.pathname.startsWith("/") &&
                                                !router.pathname.startsWith("/us")
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <UKFlag />
                                    </DropdownItem>
                                    <DropdownItem
                                        href="/us"
                                        title={`United States`}
                                        aria-label={`United States`}
                                        className={router.pathname.startsWith("/us") ? "active" : ""}
                                    >
                                        <USFlag />
                                    </DropdownItem>
                                    <DropdownItem
                                        href="https://www.lorienglobal.de/"
                                        title={`Germany`}
                                        className={
                                            router.pathname.startsWith("/au") &&
                                                !router.pathname.startsWith("/us")
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <DEFlag />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
            </div>
            <div className="d-flex align-items-center d-md-flex">
              <button
                className="search-icon d-flex d-none d-lg-inline-flex"
                aria-label="Search"
                onClick={toggleSearch}
              >
                <SearchIcon />
              </button>
              <Link
                href={`${router.pathname.startsWith("/us")
                  ? "/us/job-seekers#join-our-talent-network"
                  : "/job-seekers#join-our-talent-network"
                  }`}
              >
                <a className="secondaryBtn no-arrow text-nowrap m-0 d-none d-lg-inline-block ms-2">
                  Join Our Talent Network
                </a>
              </Link>
              <Link
                href={`${router.pathname.startsWith("/us")
                  ? "/us/contact-us"
                  : "/contact-us"
                  }`}
              >
                <a
                  className="tertiaryBtn no-arrow text-nowrap m-0 ms-2"
                  aria-label="Contact Us"
                >
                  <span className="telephone-icon me-lg-2">
                    <Telephone />
                  </span>
                  <span className="d-none d-lg-inline-block">Contact Us</span>
                </a>
              </Link>
              <button
                aria-label="Menu"
                className={`d-inline-block d-lg-none mobile-menu ms-2 position-relative ${isOpen ? "active" : ""
                  }`}
                onClick={() => setIsOpen((isOpen) => !isOpen)}
              >
                Menu
              </button>
            </div>
          </div>
          {isAboutDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/*<p className="mb-0 fs-6 primary-color"><small>Overview</small></p>*/}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/about-us/`}
                      >
                        <a>
                          <strong>About Us</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/about-us/join-us/`}
                      >
                        <a>
                          <strong>Join Us</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/about-us/meet-the-team/`}
                      >
                        <a>
                          <strong>Meet the Team</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/sustainable-recruitment/social-value`}
                      >
                        <a>
                          <strong>Social Value</strong>
                        </a>
                      </Link>
                    </NavItem>

                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/about-us/csr-partnerships/`}
                      >
                        <a>
                          <strong>CSR & Partnerships</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/switzerland/`}
                      >
                        <a>
                          <strong>Carbon60 Switzerland</strong>
                        </a>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
                {/* <Col md={9}>
                                    <p className="mb-0 fs-6 primary-color"><small>Discover the solutions</small></p>
                                    <Nav className="list-unstyled">
                                        {SubMenuNavigations.category_values.map((post, index) => (
                                            post.parent.en === "About" ?
                                                <NavItem key={index} className="w-100 mt-3 mb-1">
                                                    <Link href={`/solutions/${post.url_slug}/`}>
                                                        <a>
                                                            <strong>{post.title.en}</strong>
                                                        </a>
                                                    </Link>
                                                </NavItem> : ""
                                        ))}
                                    </Nav>
                                </Col> */}
              </Row>
            </div>
          )}
          {isInsightsDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/*<p className="mb-0 fs-6 primary-color"><small>Overview</small></p>*/}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/blog/`}
                      >
                        <a>
                          <strong>News and Insights</strong>
                        </a>
                      </Link>
                    </NavItem>
                  </Nav>
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/blog/tag/case-studies`}
                      >
                        <a>
                          <strong>Case Studies</strong>
                        </a>
                      </Link>
                    </NavItem>
                  </Nav>
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/blog/tag/career-advice`}
                      >
                        <a>
                          <strong>Career Advice</strong>
                        </a>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
                {/* <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                                    <p className="mb-0 fs-6 primary-color"><small>Discover the insights</small></p>
                                    <Nav className="list-unstyled">
                                        <NavItem className="w-100 mt-3 mb-1" onClick={() => closeAllDropdowns()}>
                                            <Link href="/blog/success-story/">
                                                <a>
                                                    <strong>Success story</strong>
                                                </a>
                                            </Link>
                                        </NavItem>
                                    </Nav>
                                </Col> */}
              </Row>
            </div>
          )}
          {isClientsDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/*<p className="mb-0 fs-6 primary-color"><small>Overview</small></p>*/}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      // onClick={() => closeAllDropdowns()}
                      onClick={() => toggleDropdown("Solutions")}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/business-solutions/`}
                      >
                        <a onClick={(e) => { !isSolutionssDropdownOpen ? e.preventDefault() : closeAllDropdowns() }}>
                          <strong>Business Solutions</strong>
                          <span><Arrow /></span>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      // onClick={() => closeAllDropdowns()}
                      onClick={() => toggleDropdown("Sectors")}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/sectors/`}
                      >
                        <a onClick={(e) => { !isSectorsDropdownOpen ? e.preventDefault() : closeAllDropdowns() }}>
                          <strong>Our Sectors</strong>
                          <span><Arrow /></span>
                        </a>
                      </Link>
                    </NavItem>
                    {/* <NavItem
                      className="w-100 mt-3 mb-1"
                      // onClick={() => closeAllDropdowns()}
                      onClick={() => toggleDropdown("Sustainable")}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/sustainable-recruitment/`}
                      >
                        <a onClick={(e) => { !isSustainableDropdownOpen ? e.preventDefault() : closeAllDropdowns() }}>
                          <strong>Sustainable Recruitment</strong>
                          <span><Arrow /></span>
                        </a>
                      </Link>
                    </NavItem> */}
                  </Nav>
                </Col>
                {isSolutionssDropdownOpen && (
                  <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                    <Nav className="list-unstyled">
                      {SolutionsNavigation.category_values.map((post, index) => (
                        <NavItem
                          key={index}
                          className="w-100 mt-3 mb-1"
                          onClick={() => closeAllDropdowns()}
                        >
                          <Link
                            href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                              }/business-solutions/${post.url_slug}/`}
                          >
                            <a>
                              <strong>{post.title.en}</strong>
                            </a>
                          </Link>
                        </NavItem>
                      ))}
                    </Nav>
                  </Col>
                )}
                {isSustainableDropdownOpen && (
                  <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                    <Nav className="list-unstyled">
                      {SustainableNavigation.category_values
                        .filter(post => post.title.en !== 'Social Value')
                        .map((post, index) => (
                          <NavItem
                            key={index}
                            className="w-100 mt-3 mb-1"
                            onClick={() => closeAllDropdowns()}
                          >
                            <a href={`/sustainable-recruitment/${post.url_slug}`}>
                              <strong>{post.title.en}</strong>
                            </a>
                          </NavItem>
                        ))}

                    </Nav>
                  </Col>
                )}
                {isSectorsDropdownOpen && (
                  <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                    <Nav className="list-unstyled">
                      {SectorsNavigation.category_values.filter((cat) => cat.solutions?.en === "Business Solutions").map((post, index) => (
                        <>
                          <NavItem
                            key={index}
                            className="w-100 mt-3 mb-1"
                            onClick={() => closeAllDropdowns()}
                          >
                            <a
                              href={`/sectors/${post.url_slug}`}
                            >
                              <strong>{post.title.en}</strong>
                            </a>
                          </NavItem>
                          {SectorsNavigation.category_values.filter((cat) => cat.parent?.id === post.id).map((child, index) => (
                            <NavItem
                              key={index}
                              className="w-100"
                              onClick={() => closeAllDropdowns()}
                            >
                              <a
                                href={`/sectors/${child.url_slug}`}
                              >
                                <span>{child.title.en}</span>
                              </a>
                            </NavItem>
                          ))}
                        </>
                      ))}
                    </Nav>
                  </Col>
                )}
              </Row>
            </div>
          )}
          {isContractorsDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/*<p className="mb-0 fs-6 primary-color"><small>Overview</small></p>*/}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/contractors/`}
                      >
                        <a>
                          <strong>Contractors</strong>
                        </a>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </div>
          )}
          {isJobSeekersDropdownOpen && (
            <div className="dropdown-navigations position-absolute py-4 px-5 w-100 mt-1">
              <Row>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/*<p className="mb-0 fs-6 primary-color"><small>Overview</small></p>*/}
                  <Nav className="list-unstyled">
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/job-seekers/`}
                      >
                        <a>
                          <strong>Job Seekers</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/jobs/`}
                      >
                        <a>
                          <strong>Job Search</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/job-seekers/featured-employers/`}
                      >
                        <a>
                          <strong>Featured Employers</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/job-seekers/training-and-apprenticeships/`}
                      >
                        <a>
                          <strong>Training and Apprenticeships</strong>
                        </a>
                      </Link>
                    </NavItem>
                    <NavItem
                      className="w-100 mt-3 mb-1"
                      onClick={() => closeAllDropdowns()}
                    >
                      <Link
                        href={`${router.pathname.startsWith("/us") ? "/us/" : ""
                          }/armed-forces-support/`}
                      >
                        <a>
                          <strong>Armed Forces Support</strong>
                        </a>
                      </Link>
                    </NavItem>
                    {/*<NavItem className="w-100 mt-3 mb-1" onClick={() => closeAllDropdowns()}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/contractors/`}>
                                                <a>
                                                    <strong>Contractors</strong>
                                                </a>
                                            </Link>
                                        </NavItem>
                                        <NavItem className="w-100 mt-3 mb-1" onClick={() => closeAllDropdowns()}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/blog/`}>
                                                <a>
                                                    <strong>Insights</strong>
                                                </a>
                                            </Link>
                                        </NavItem>
                                        <NavItem className="w-100 mt-3 mb-1" onClick={() => closeAllDropdowns()}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/about-us/`}>
                                                <a>
                                                    <strong>About Us</strong>
                                                </a>
                                            </Link>
                                        </NavItem> */}
                  </Nav>
                </Col>
                <Col md={3} className="pe-md-5 mb-4 mb-md-0">
                  {/*<p className="mb-0 fs-6 primary-color"><small>Overview</small></p>*/}
                  <Nav className="list-unstyled">
                    {SectorsNavigation.category_values.filter((cat) => cat.solutions?.en === "Job Seekers").map((post, index) => (
                      <NavItem
                        key={index}
                        className="w-100 mt-3 mb-1"
                        onClick={() => closeAllDropdowns()}
                      >
                        <a
                          href={`/jobs#/${post.url_slug}`}
                          onClick={() => handleLinkClick(post.url_slug)}
                        >
                          <strong>{post.title.en}</strong>
                        </a>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </section>
      <div
        className={`${styles.root} mobile-dropdown ${isOpen ? "active" : "overflow-hidden"
          } d-inline-block d-lg-none`}
      >
        <Container>
          <div className="d-flex align-items-center mb-4 mobile-search">
            <div className="w-100 position-relative filters">
              <input
                type="text"
                id="search"
                placeholder="Site Search..."
                onChange={handleSearchChange}
                value={searchQuery}
              />
              <span className="icon d-inline-block position-absolute">
                <SearchIcon />
              </span>
              {searchResults.length > 0 && (
                <div className="mt-4 search-results">
                  <p className="fs-4">
                    <strong>Search Results:</strong>
                  </p>
                  <ul>
                    {searchResults.map((post, index) => (
                      <li key={index}>
                        <Link href={`/blog/${post.item.url_slug}`}>
                          <a onClick={() => setIsOpen((isOpen) => !isOpen)}>
                            {post.item.title.en}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Container>
        <div className="d-flex flex-wrap justify-content-between h-100 mobileWrapper">
          <div className="w-100">
            <Nav className="list-unstyled">
              {MainNavigation?.category_values.map((post, index) => (
                <NavItem
                  key={index}
                  className={`w-100 position-relative parent ${post.has_children.en ? "has-children" : null
                    }`}
                  onClick={() => closeAllDropdowns()}
                >
                  {post.has_children.en ? (
                    <>
                      <Accordion open={open} toggle={toggle}>
                        {post.title.en == "About Us" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p><Link href="/about-us/"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>About Us</a></Link></p>
                              <p>
                                <Link href="/about-us/join-us/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Join Us
                                  </a>
                                </Link>
                              </p>
                              <p>
                                <Link href="/about-us/meet-the-team/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Meet the Team
                                  </a>
                                </Link>
                              </p>
                              <p>
                                <Link href="/sustainable-recruitment/social-value">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Social Value
                                  </a>
                                </Link>
                              </p>
                              <p>
                                <Link href="/about-us/csr-partnerships/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    CSR & Partnerships
                                  </a>
                                </Link>
                              </p>
                              <p>
                                <Link href="/switzerland">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Carbon60 Switzerland
                                  </a>
                                </Link>
                              </p>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                        {post.title.en == "Business Solutions" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p>
                                {" "}
                                <Link href="/business-solutions/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    } className="fw-bold"
                                  >
                                    Business Solutions
                                  </a>
                                </Link>
                              </p>
                              {SolutionsNavigation.category_values.map((post, index) => (
                                <p index={index} className="ps-3"><Link href={`${router.pathname.startsWith("/us") ? "/us/" : ""}/business-solutions/${post.url_slug}/`}><a onClick={() => setIsOpen((isOpen) => !isOpen)}>{post.title.en}</a></Link></p>
                              ))}
                              <p className="mt-4 mb-0"><Link href="/sectors"><a onClick={() => setIsOpen((isOpen) => !isOpen)} className="fw-bold">Our Sectors</a></Link></p>
                              <Nav className="list-unstyled">
                                {SectorsNavigation.category_values.filter((cat) => cat.solutions?.en === "Business Solutions").map((post, index) => (
                                  <>
                                    <NavItem
                                      key={index}
                                      className="w-100 mt-3 mb-1"
                                      onClick={() => closeAllDropdowns()}
                                    >
                                      <a
                                        href={`/sectors/${post.url_slug}`}
                                      >
                                        <strong>{post.title.en}</strong>
                                      </a>
                                    </NavItem>
                                    {SectorsNavigation.category_values.filter((cat) => cat.parent?.id === post.id).map((child, index) => (
                                      <NavItem
                                        key={index}
                                        className="w-100"
                                        onClick={() => closeAllDropdowns()}
                                      >
                                        <p className="ps-3">
                                          <a
                                            href={`/sectors/${child.url_slug}`}
                                            >
                                            <span>{child.title.en}</span>
                                          </a>
                                        </p>
                                      </NavItem>
                                    ))}
                                  </>
                                ))}
                              </Nav>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                        {post.title.en == "Contractors" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p><Link href="/contractors"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Contractors</a></Link></p>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                        {post.title.en == "Job Seekers" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p>
                                {" "}
                                <Link href="/job-seekers/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Job Seekers
                                  </a>
                                </Link>
                              </p>
                              <p>
                                {" "}
                                <Link href="/jobs/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Job Search
                                  </a>
                                </Link>
                              </p>
                              <p>
                                {" "}
                                <Link href="/job-seekers/featured-employers/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Featured Employers
                                  </a>
                                </Link>
                              </p>
                              <p>
                                {" "}
                                <Link href="/job-seekers/training-and-apprenticeships/">
                                  <a
                                    onClick={() =>
                                      setIsOpen((isOpen) => !isOpen)
                                    }
                                  >
                                    Training and Apprenticeships
                                  </a>
                                </Link>
                              </p>
                              <p><Link href="/armed-forces-support"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Armed Forces Support</a></Link></p>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                        {post.title.en == "Insights" && (
                          <AccordionItem>
                            <AccordionHeader targetId={`${index}`}>
                              {post.title.en}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <p><Link href="/blog"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>News and Insights</a></Link></p>
                              <p><Link href="/blog/tag/case-studies"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Case Studies</a></Link></p>
                              <p><Link href="/blog/tag/career-advice"><a onClick={() => setIsOpen((isOpen) => !isOpen)}>Career Advice</a></Link></p>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                      </Accordion>{" "}
                    </>
                  ) : (
                    <Link href={`${post.link.en}`}>
                      <a
                        onClick={() => setIsOpen((isOpen) => !isOpen)}
                        className="d-block"
                      >
                        <strong>{post.title.en}</strong>
                      </a>
                    </Link>
                  )}
                </NavItem>
              ))}
            </Nav>
          </div>
          {/* <div className="mobile-language w-100 d-flex align-items-end">
                        <div className="w-100 mb-4">
                            <hr></hr>
                            <Container>
                                <UncontrolledDropdown className="lang_select">
                                    <DropdownToggle>
                                        <span
                                            className={
                                                router.pathname.startsWith("/") &&
                                                    !router.pathname.startsWith("/us")
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <UKFlag /> UK
                                        </span>
                                        <span
                                            className={router.pathname.startsWith("/us") ? "active" : ""}
                                        >
                                            <USFlag /> US
                                        </span>
                                        <span
                                            className={
                                                router.pathname.startsWith("/au") &&
                                                    !router.pathname.startsWith("/us")
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <DEFlag /> DE
                                        </span>
                                    </DropdownToggle>

                                    <DropdownMenu aria-labelledby="dropdownMenuButton">
                                        <DropdownItem
                                            href="/"
                                            title={`United Kingdom`}
                                            className={
                                                router.pathname.startsWith("/") &&
                                                    !router.pathname.startsWith("/us")
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <UKFlag /> UK
                                        </DropdownItem>
                                        <DropdownItem
                                            href="/us"
                                            title={`United States`}
                                            className={router.pathname.startsWith("/us") ? "active" : ""}
                                        >
                                            <USFlag /> US
                                        </DropdownItem>
                                        <DropdownItem
                                            href="https://www.lorienglobal.de/"
                                            title={`Germany`}
                                            className={
                                                router.pathname.startsWith("/au") &&
                                                    !router.pathname.startsWith("/us")
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <DEFlag /> DE
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Container>
                        </div>
                    </div> */}
        </div>
      </div>
      {isSearchOpen && (
        <section className={`${styles.root} site-search d-none d-md-block`}>
          <Container>
            <div className="d-flex align-items-center">
              <div className="w-100 position-relative filters">
                <input
                  type="text"
                  id="search"
                  placeholder="Site Search..."
                  // onChange={handleSearchChange}
                  // value={searchValue}
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <span className="icon d-inline-block position-absolute">
                  <SearchIcon />
                </span>
              </div>
              <button
                className={`primaryBtn no-arrow border-0 m-0 ms-3`}
                aria-label="Search"
                onClick={() => toggleSearch()}
              >
                <CloseMark />
              </button>
            </div>
            {searchResults.length > 0 && (
              <div className="mt-4 search-results">
                <p className="fs-4">
                  <strong>Search Results:</strong>
                </p>
                <ul>
                  {searchResults.map((post, index) => (
                    <li key={index}>
                      <Link href={`/blog/${post.item.url_slug}`}>
                        <a onClick={() => toggleSearch()}>
                          {post.item.title.en}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </section>
      )}
    </>
  );
}
